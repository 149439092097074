<template>
  <div>
    <v-card
      v-for="({collectionId, reverseRelations}) in reverseRelationsGroupedByCollection"
      :key="collectionId">
      <v-card-title>{{collectionsConfig.getCollectionLabel(collectionId)}}</v-card-title>
      <v-card-text>
        <v-data-table
          v-sortable-table="'.drag-handle'"
          :items="reverseRelations"
          item-key="relationId"
          :hide-default-headers="true"
          :hide-default-actions="true"
          @drag-sorted="sortedInGroup($event, reverseRelations, reverseRelationsGroupedByCollection)"
        >
          <template v-slot:body="{items}">
            <tbody>
              <tr v-for="item in items" :key="item.relationId">
                <td class="drag-handle">
                  <v-icon>swap_vert</v-icon>
                  {{item.relationId}}
                </td>
                <td>
                  {{collectionsConfig.getCollectionLabel(item.sourceCollectionId)}}
                </td>
                <td>
                  &larr;
                </td>
                <td>
                  {{collectionsConfig.getCollectionLabel(item.targetCollectionId)}}
                </td>
                <td _style="width: 100%">
                  <v-text-field label="Display name" :placeholder="item.label" v-model="item.label" />
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import {mapGetters} from 'vuex'
import SortableTable from '@/directives/sortable-table'
import groupBy from 'lodash/groupBy'
export default {
  props: ['reverseRelationClientSettings', 'collectionSchemas', 'relationSchemas'],
  directives: {
    SortableTable
  },
  computed: {
    ...mapGetters(['collectionsConfig']),
    reverseRelationsGroupedByCollection () {
      /**
        * General idea is
        * - get sorted collections
        * - and group relations by source collection
        * - and within that group allow sorting of relations
        *
        * Order (order property) is unique within group (i.e. collection)
        * but not globaly uniuqe, which should have no negative impact
        */
      let reverseRelationsBySourceCollectionId = groupBy(
        this.reverseRelationClientSettings.reverseRelations, 'targetCollectionId')

      let groups = this.collectionsConfig.mapCollectionSchemas(this.collectionSchemas)
        .map(({collectionId}) => ({
          collectionId,
          reverseRelations: reverseRelationsBySourceCollectionId[collectionId]
        }))
        .filter(({reverseRelations}) => reverseRelations && reverseRelations.length)

      return groups
    }
  },
  methods: {
    sortedInGroup ({newIndex, oldIndex} = event, reverseRelations, groups) {
      reverseRelations.splice(newIndex, 0, reverseRelations.splice(oldIndex, 1)[0])

      // whenever the order changes due to drag n drop we layout the relations in
      // canonical order
      groups.reduce((l, {reverseRelations}) => l.concat(reverseRelations), [])
        .forEach((r, index) => r.order = index + 1)
    }
  }
}
</script>
<style>
  .drag-handle {
    cursor: grab;
  }
</style>
