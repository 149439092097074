<template>
  <layout-page>
    <template v-slot:toolbar-title>
      <v-toolbar-title>
        {{translate('Reverse relations')}}
      </v-toolbar-title>
    </template>

    <template v-slot:toolbar-items>
      <v-toolbar-items>
        <v-btn
          :loading="relationData.loading"
          text
          @click="savereverseRelationClientSettings(relationData.reverseRelationsClientSettings)">
            <v-icon>save</v-icon> {{translate('Save', 'save')}}
        </v-btn>
      </v-toolbar-items>
    </template>

    <schemas-grid
      :reverseRelationClientSettings="relationData.reverseRelationsClientSettings"
      :relationSchemas="relationData.relationSchemas"
      :collectionSchemas="relationData.collectionSchemas"
      />
  </layout-page>
</template>
<script>
import {mapGetters, mapActions} from 'vuex'
import LayoutPage from '@/components/layout/layout-page.vue'
import SchemasGrid from './schemas-grid'

export default {
  components: {
    LayoutPage,
    SchemasGrid
  },
  computed: {
    ...mapGetters(['api', 'reverseRelationsConfig', 'translate']),
  },
  asyncComputed: {
    relationData: {
      async get () {
        let [relationSchemas, collectionSchemas] = await Promise.all([
          this.api.relations.getRelationSchemas(),
          this.api.collections.getCollectionSchemas()
        ])
        return {
          relationSchemas,
          collectionSchemas,
          reverseRelationsClientSettings: this.reverseRelationsConfig.mapClientSettings(relationSchemas)
        }
      },
      default: {
        loading: true,
        relationSchemas: [],
        collectionSchemas: [],
        reverseRelationsClientSettings: {reverseRelations: {}}
      }
    }
  },
  methods: {
    ...mapActions(['updateClientConfig']),
    savereverseRelationClientSettings (clientSettings) {
      let cs = this.reverseRelationsConfig.normalizeClientSettings(clientSettings)
      return this.updateClientConfig (cs)
    }
  }
}
</script>
